/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...defaultSettings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...defaultSettings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */

import applyItaliaConfig from '@italia/config/italiaConfig';
// import { VirtualAssistant } from '@package/components';
import '@plone/volto/config';

import {
  ContentImage,
  EventoCosE,
  EventoLuoghi,
  EventoDateOrari,
  EventoCosti,
  EventoDocumenti,
  EventoContatti,
  EventoPadreEFigli,
  EventoUlterioriInformazioni,
  EventoSponsors,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);
  config.settings.italiaThemeViewsConfig = {
    ...config.settings.italiaThemeViewsConfig,
    imagePosition: 'documentBody', // possible values: afterHeader, documentBody
    Event: {
      sections: [
        {
          /* HEADER IMAGE */

          component: ContentImage,
          props: { position: 'documentBody' },
        },
        { /* COS'è */ component: EventoCosE },
        { /* LUOGHI */ component: EventoLuoghi },
        { /* DATE E ORARI */ component: EventoDateOrari },
        { /* COSTI */ component: EventoCosti },
        { /* DOCUMENTI */ component: EventoDocumenti },
        { /* EVENTS */ component: EventoPadreEFigli },
        { /* SPONSORS */ component: EventoSponsors },
        { /* ULTERIORI INFORMAZIONI */ component: EventoUlterioriInformazioni },
        { /* CONTATTI */ component: EventoContatti },
      ],
    },
  };
  config.settings.siteProperties = {
    ...config.settings.siteProperties,
    siteTitle: "Camera di Commercio dell'Emilia",
    siteSubtitle: '',
    parentSiteTitle: null, //'Unioncamere',
    parentSiteURL: null, //'http://www.unioncamere.gov.it/',
    subsiteParentSiteTitle: "Camera di Commercio dell'Emilia",
  };
  config.settings.siteProperties = {
    ...config.settings.siteProperties,
    headerslimTertiaryMenu: {
      it: [
        {
          url: '/menu-principale/prenotazioni-on-line',
          title: 'Prenotazioni on line',
        },
        {
          url: '/menu-principale/la-camera/sipa-sito-pagamenti-online-pagopa',
          title: 'Pagamenti on line',
        },
        {
          url: 'https://unioncamere.my.site.com/cciaaEmilia',
          title: 'Accedi',
        },
        {
          url: 'https://unioncamere.my.site.com/cciaaEmilia/s/registration',
          title: 'Registrati',
        },
      ],
    },
  };

  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: {
      ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
      last_updated: '2023-06-06T11:30:00+00:00',
      technical: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
        choices: [
          {
            config_key: 'MATOMO',
            text: {
              it: {
                title: 'Matomo',
                description:
                  "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
              },
              en: {
                title: 'Matomo',
                description:
                  'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
              },
              fr: {
                title: 'Matomo',
                description:
                  "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
              },
            },
          },
          /*...config.settings[
          'volto-gdpr-privacy'
        ].defaultPanelConfig.technical.choices.filter(
          (f) =>
            f.config_key !== 'GANALYTICS' && f.config_key !== 'GRECAPTCHA',
        ),*/
        ],
      },
    },
  };

  //Emilly Virtual Assistant - Works only for Reggio Emillia at the moment
  /* config.settings.appExtras = [
    ...(config.settings.appExtras || []),
    {
      match: '',
      component: VirtualAssistant,
    },
  ]; */
  return config;
}
